<script lang="ts" setup>
import { ref, watch } from 'vue'

import type { Props } from './types'

const props = defineProps<Props>()

const lastSource = ref(props.sources.slice(-1)[0])
const filteredSources = props.sources.slice(0, -1)

function getSrc (src: string | undefined, width: number): string {
  if (src) {
    return src
  }

  return `${props.src}?auto=compress,enhance,format&w=${width}`
}

watch(() => props.sources, () => {
  lastSource.value = props.sources.slice(-1)[0]
})
</script>

<script lang="ts">
export default {
  name: 'AppImage'
}
</script>

<template>
  <picture :data-lh-id="dataLhId">
    <source
      v-for="(source, index) in filteredSources"
      :key="`source-${index}`"
      :media="`(max-width: ${source.mediaWidth}px)`"
      :srcset="getSrc(source.src, source.imageWidth || source.mediaWidth)"
      :height="source.imageHeight"
      :width="source.imageWidth">

    <img
      :src="getSrc(lastSource.src, lastSource.imageWidth || lastSource.mediaWidth)"
      :alt="alt"
      :class="classImg">
  </picture>
</template>
